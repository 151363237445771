<template>
  <div class="card">
    <div class="card-header">
      <div>
        <div class="card-title">{{ $t('page.settings.about_my_business.title') }}</div>
        <small>{{ $t('page.settings.about_my_business.description') }}</small>
      </div>
    </div>

    <div class="card-body">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div class="flex flex-col gap-4">
          <div>
            <label
              for="category-select"
              :class="{'category-label-error': !!currentErrors?.category}"
            >
              {{ $t('page.settings.category.form.category.label') }}
            </label>
            <Multiselect
              id="category-select"
              label="name"
              valueProp="uid"
              v-model="selectedCategory"
              :options="categories"
              :searchable="true"
              trackBy="name"
              :placeholder="$t('page.settings.category.form.category.placeholder')"
              :noResultsText="$t('page.settings.category.form.category.no_results')"
              :noOptionsText="$t('page.settings.category.form.category.no_options')"
              :class="{'multiselect-error': !!currentErrors?.category}"
            />
            <div v-if="!!currentErrors?.category" class="text-sm text-red-700 block">{{ currentErrors?.category }}</div>
            <div class="mt-2">
              <a
                href="javascript:void(0)"
                @click.prevent="openCategoryInfo"
                class="text-sm text-blue-600"
              >
                <i class="far fa-question-circle text-blue-600"></i>
                {{ $t('page.settings.category.action.category.help_message') }}
              </a>
            </div>
          </div>

          <div>
            <base-input
              :label="$t('page.settings.profile_identification.form.website.label')"
              name="website"
              type="url"
              v-model="profile.website"
              autocomplete="off"
              left-icon="fas fa-globe"
              :help="$t('page.settings.profile_identification.form.website.help_message')"
              :has-error="!!currentErrors?.website"
              :error-message="currentErrors?.website"
            />
          </div>
        </div>
        <div class="flex flex-col gap-4">
          <div>
            <base-textarea
              :label="$t('form.label.about_specialization')"
              name="specialization"
              rows="2"
              autocomplete="off"
              v-model="profile.specialization"
              :placeholder="$t('form.placeholder.about_specialization')"
              :help="$t('sentence.min_characters-dynamic', { count: 20 })"
              :has-error="!!currentErrors?.specialization"
              :error-message="currentErrors?.specialization"
              show-counter
              :counter-min="20"
            />
          </div>

          <div>
            <base-textarea
              :label="$t('form.label.about_profile_product')"
              name="profileProduct"
              rows="2"
              autocomplete="off"
              v-model="profile.profileProduct"
              :placeholder="$t('form.placeholder.about_profile_product')"
              :help="$t('sentence.min_characters-dynamic', { count: 20 })"
              :has-error="!!currentErrors?.profileProduct"
              :error-message="currentErrors?.profileProduct"
              show-counter
              :counter-min="20"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card mt-8">
    <div class="card-header">
      <div>
        <div class="card-title">Sobre o meu conteúdo</div>
        <small>{{ $t('page.settings.about_my_business.description') }}</small>
      </div>
    </div>

    <div class="card-body">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div class="flex flex-col gap-4">
          <div>
            <label class="">{{ $t('sentence.voice_tone') }}</label>
            <VoiceToneSelector :model-value="profile.voiceTone" />
          </div>
        </div>

        <div class="flex flex-col gap-4">
          <div>
            <label
              for="language"
              :class="{'text-red-700': !!currentErrors?.language}"
            >
              {{ $t('page.settings.profile-language.form.profile-language.label') }}
            </label>
            <Multiselect
              v-model="profile.language"
              name="language"
              :options="languages"
              mode="single"
              :placeholder="$t('page.settings.profile-language.form.profile-language.label')"
              :class="{'multiselect-error': !!currentErrors?.language}"
            />
            <div>
              <span
                class="text-sm text-red-700 block"
                v-if="!!currentErrors?.language"
                v-html="currentErrors?.language">
              </span>
            </div>
          </div>

          <div>
            <base-textarea
              :label="$t('form.label.about_audience')"
              name="audience"
              rows="2"
              autocomplete="off"
              v-model="profile.audience"
              :placeholder="$t('form.placeholder.about_audience')"
              :help="$t('sentence.min_characters-dynamic', { count: 20 })"
              :has-error="!!currentErrors?.audience"
              :error-message="currentErrors?.audience"
              show-counter
              :counter-min="20"
            />
          </div>

          <div>
            <base-textarea
              :label="$t('form.label.about_content_purpose')"
              name="contentPurpose"
              rows="2"
              autocomplete="off"
              v-model="profile.contentPurpose"
              :placeholder="$t('form.placeholder.about_content_purpose')"
              :help="$t('sentence.min_characters-dynamic', { count: 20 })"
              :has-error="!!currentErrors?.contentPurpose"
              :error-message="currentErrors?.contentPurpose"
              show-counter
              :counter-min="20"
            />
          </div>
        </div>
      </div>
      
      <div class="mt-8">
        <form
          @submit.prevent="addKeyword"
        >
          <label
            for="keyword-select"
            :class="{'keywords-label-error': !!currentErrors?.keywords}"
            v-html="$t('page.settings.category.sentencel.enter_keywords-html')"
          />
          <div class="flex items-center">
            <input
              class="border border-gray-300 rounded-tr-none rounded-br-none block w-full py-2 px-4"
              type="text"
              :placeholder="$t('page.settings.category.form.keywords.placeholder')"
              :disabled="form.keywords.length >= 3"
              :class="{'keywords-input-error': !!currentErrors?.keywords}"
            />
            <button
              class="btn btn-secondary rounded-tl-none rounded-bl-none"
              type="submit"
              :disabled="form.keywords.length >= 3"
            >
              {{ $t('action.insert') }}
            </button>
          </div>
          <div v-if="!!currentErrors?.keywords" class="text-sm text-red-700 block">{{ currentErrors?.keywords }}</div>
        </form>

        <div class="mt-4">
          <ol class="list-decimal">
            <li
              class="flex justify-between items-center border-b py-2 px-4"
              v-for="(keyword, index) in form.keywords" :key="keyword">
              {{ index + 1 }} - {{ keyword }}
              <button
                @click="removeKeyword(index)"
              >
                <i class="fas fa-times text-sm hover:text-red-600"></i>
              </button>
            </li>
          </ol>
        </div>
        <div class="mt-2">
          <a
            href="javascript:void(0)"
            @click.prevent="openKeywordInfo"
            class="text-sm text-blue-600"
          >
          <i class="far fa-question-circle text-blue-600"></i>
            {{ $t('page.settings.category.action.keyword.help_message') }}
          </a>
        </div>
      </div>
    </div>
  </div>

</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref, watch } from 'vue'
import Multiselect from '@vueform/multiselect'
import { useCategory } from '@/composables/useCategory'
import { useI18n } from 'vue-i18n'
import BaseTextarea from '../base/BaseTextarea.vue'
import BaseInput from '../base/BaseInput.vue'
import Swal from 'sweetalert2'
import VoiceToneSelector from './VoiceToneSelector.vue'
import { availableLanguages } from '@/plugins/i18n'

interface CategoryItem {
  uid: string
  name: string
  translateKey?: string
}

export default defineComponent({
  components: {
    Multiselect,
    BaseInput,
    BaseTextarea,
    VoiceToneSelector
  },

  props: {
    form: {
      type: Object,
      required: true
    },
    currentErrors: {
      type: Object,
      default: () => {}
    }
  },

  emits: [
    'update:form'
  ],

  setup (props, { emit }) {
    const profile = ref<any>({
      category: props.form.category,
      keywords: props.form.keywords,
      website: props.form.website,
      description: props.form.description,
      specialization: props.form.specialization,
      audience: props.form.audience,
      contentPurpose: props.form.contentPurpose,
      voiceTone: props.form.voiceTone,
      profileProduct: props.form.profileProduct,
      language: props.form.language
    })

    const { getCategories } = useCategory()
    const { t } = useI18n()

    const categories = ref<CategoryItem[]>([])
    const languages = availableLanguages
      .filter((language) => language.context.includes('content'))
      .map((language) => ({
        value: language.value,
        label: language.caption,
      }))

    const selectedCategory = ref<string>(profile.value.category ? profile.value.category.uid : '')

    onBeforeMount(async () => {
      categories.value = await getCategories()
    })

    watch(selectedCategory, (categoryId) => {
      profile.value.category = categories.value.find((category) => category.uid === categoryId)
    })

    const addKeyword = (e: Event & { target: HTMLFormElement }) => {
      const element = e.target
      const keywordInput = element.querySelector('input')
      if (!keywordInput) {
        return
      }

      const inputValue = keywordInput.value
      if (profile.value.keywords.length < 3 && !!inputValue) {
        if (!profile.value.keywords.find((key: string) => key === inputValue)) {
          profile.value.keywords.push(inputValue)
        }
        keywordInput.value = ''
      }
    }

    const removeKeyword = (arrayIndex: number) => {
      profile.value.keywords.splice(arrayIndex, 1)
    }

    const openCategoryInfo = () => {
      Swal.fire({
        title: t('state.settings.category.about_category.titlle'),
        confirmButtonText: t('state.settings.category.about_category.action_confirm'),
        html: t('state.settings.category.about_category.message-html')
      })
    }

    const openKeywordInfo = () => {
      Swal.fire({
        title: t('state.settings.category.about_keywords.titlle'),
        confirmButtonText: t('state.settings.category.about_keywords.action_confirm'),
        html: t('state.settings.category.about_keywords.message-html')
      })
    }

    watch(profile, (newValue) => {
      emit('update:form', {
        ...newValue,
        ...(newValue.website && {website: newValue.website?.trim().length && !newValue.website.startsWith('http') ? `https://${newValue.website?.trim()}` : newValue.website?.trim()})
      })
    }, { deep: true })

    return {
      profile,
      categories,
      selectedCategory,
      languages,
      addKeyword,
      removeKeyword,
      openCategoryInfo,
      openKeywordInfo
    }
  }
})
</script>

<style>
.multiselect-error .multiselect-search, .multiselect-error .multiselect-input {
  border-width: 1px !important;
  --tw-border-opacity: 1 !important;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
}
.keywords-input-error {
  border-width: 1px !important;
  --tw-border-opacity: 1 !important;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
}
.category-label-error, .keywords-label-error {
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity));
}
label strong {
  font-weight: 600;
}
li input[type="radio"]:checked + label {
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}
</style>