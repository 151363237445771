<template>
  <div class="flex flex-col gap-4">
    <!-- page.voice_tone.humor -->
    <ul class="grid grid-cols-8 gap-2">
      <li class="flex items-center justify-end">
        <i class="far fa-laugh-beam"></i>
      </li>

      <li class="flex items-center w-full col-span-2">
        <input class="hidden peer" name="humor" type="radio" id="humor-1" value="funny" v-model="voiceTone.humor"/>
        <label
          class="btn outline w-full font-normal"
          :for="`humor-1`"
        >
          {{ $t('page.voice_tone.funny') }}
        </label>
      </li>

      <li class="flex items-center w-full col-span-2">
        <input class="hidden peer" name="humor" type="radio" id="humor-2" value="" v-model="voiceTone.humor"/>
        <label
          class="btn outline w-full font-normal"
          for="humor-2"
        >
        {{ $t('page.voice_tone.neutral') }}
        </label>
      </li>

      <li class="flex items-center w-full col-span-2">
        <input class="hidden peer" name="humor" type="radio" id="humor-3" value="serious" v-model="voiceTone.humor"/>
        <label
          class="btn outline w-full font-normal"
          for="humor-3"
        >
          {{ $t('page.voice_tone.serious') }}
        </label>
      </li>

      <li class="flex items-center  justify-start">
        <i class="far fa-meh"></i>
      </li>
    </ul>

    <!-- page.voice_tone.formality -->
    <ul class="grid grid-cols-8 gap-2">
      <li class="flex items-center justify-end">
        <i class="fas fa-tshirt"></i>
      </li>

      <li class="flex items-center w-full col-span-2">
        <input class="hidden peer" name="formality" type="radio" id="formality-1" value="casual" v-model="voiceTone.formality"/>
        <label
          class="btn outline w-full font-normal"
          :for="`formality-1`"
        >
          {{ $t('page.voice_tone.casual') }}
        </label>
      </li>

      <li class="flex items-center w-full col-span-2">
        <input class="hidden peer" name="formality" type="radio" id="formality-2" value="" v-model="voiceTone.formality"/>
        <label
          class="btn outline w-full font-normal"
          for="formality-2"
        >
          {{ $t('page.voice_tone.neutral') }}
        </label>
      </li>

      <li class="flex items-center w-full col-span-2">
        <input class="hidden peer" name="formality" type="radio" id="formality-3" value="formal" v-model="voiceTone.formality"/>
        <label
          class="btn outline w-full font-normal"
          for="formality-3"
        >
          {{ $t('page.voice_tone.formal') }}
        </label>
      </li>

      <li class="flex items-center  justify-start">
        <i class="fab fa-black-tie"></i>
      </li>
    </ul>

    <!-- page.voice_tone.respectfulness -->
    <ul class="grid grid-cols-8 gap-2">
      <li class="flex items-center justify-end">
        <i class="far fa-angry"></i>
      </li>

      <li class="flex items-center w-full col-span-2">
        <input class="hidden peer" name="respectfulness" type="radio" id="respectfulness-1" value="respectful" v-model="voiceTone.respectfulness"/>
        <label
          class="btn outline w-full font-normal"
          :for="`respectfulness-1`"
        >
          {{ $t('page.voice_tone.respectful') }}
        </label>
      </li>

      <li class="flex items-center w-full col-span-2">
        <input class="hidden peer" name="respectfulness" type="radio" id="respectfulness-2" value="" v-model="voiceTone.respectfulness"/>
        <label
          class="btn outline w-full font-normal"
          for="respectfulness-2"
        >
          {{ $t('page.voice_tone.neutral') }}
        </label>
      </li>

      <li class="flex items-center w-full col-span-2">
        <input class="hidden peer" name="respectfulness" type="radio" id="respectfulness-3" value="irreverent" v-model="voiceTone.respectfulness"/>
        <label
          class="btn outline w-full font-normal"
          for="respectfulness-3"
        >
          {{ $t('page.voice_tone.irreverent') }}
        </label>
      </li>

      <li class="flex items-center  justify-start">
        <i class="far fa-thumbs-up"></i>
      </li>
    </ul>

    <!-- page.voice_tone.enthusiasm -->
    <ul class="grid grid-cols-8 gap-2">
      <li class="flex items-center justify-end">
        <i class="fas fa-glass-cheers"></i>
      </li>

      <li class="flex items-center w-full col-span-2">
        <input class="hidden peer" name="enthusiasm" type="radio" id="enthusiasm-1" value="enthusiastic" v-model="voiceTone.enthusiasm"/>
        <label
          class="btn outline w-full font-normal"
          :for="`enthusiasm-1`"
        >
          {{ $t('page.voice_tone.enthusiastic') }}
        </label>
      </li>

      <li class="flex items-center w-full col-span-2">
        <input class="hidden peer" name="enthusiasm" type="radio" id="enthusiasm-2" value="" v-model="voiceTone.enthusiasm"/>
        <label
          class="btn outline w-full font-normal"
          for="enthusiasm-2"
        >
          {{ $t('page.voice_tone.neutral') }}
        </label>
      </li>

      <li class="flex items-center w-full col-span-2">
        <input class="hidden peer" name="enthusiasm" type="radio" id="enthusiasm-3" value="realistic" v-model="voiceTone.enthusiasm"/>
        <label
          class="btn outline w-full font-normal"
          for="enthusiasm-3"
        >
          {{ $t('page.voice_tone.realistic') }}
        </label>
      </li>

      <li class="flex items-center  justify-start">
        <i class="fas fa-search"></i>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      type: Object,
      required: true,
    }
  },
  
  emits : [
    'update:modelValue'
  ],

  setup (props, { emit }) {
    const voiceTone = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })

    return {
      voiceTone
    }
  }
})
</script>

<style scoped>
@layer components {
  li input[type="radio"]:checked + label {
    @apply border-blue-600 text-blue-600;
  }
}
</style>